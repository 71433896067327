@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #333;
  font-size: 14px;
}

/* HTML: <div class="loader"></div> */

.tips {
  padding-left: 230px;
  font-size: 13px;
  color: gray;
}
.loader {
  width: 4px;
  color: #000;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px, 19px 0 0 5px,
    38px 0 0 5px, 57px 0 0 5px, 19px 19px 0 0px, 38px 19px 0 0px,
    57px 19px 0 0px;
  transform: translateX(-38px);
  animation: l26 2s infinite linear;
}
@keyframes l26 {
  12.5% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
      19px 0 0 5px, 38px 0 0 0px, 57px 0 0 5px, 19px 19px 0 0px, 38px 19px 0 0px,
      57px 19px 0 0px;
  }
  25% {
    box-shadow: 19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
      19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px, 19px 19px 0 0px, 38px 19px 0 5px,
      57px 19px 0 0px;
  }
  50% {
    box-shadow: 19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
      19px 0 0 0px, 38px 0 0 0px, 57px 0 0 0px, 19px 19px 0 0px, 38px 19px 0 0px,
      57px 19px 0 5px;
  }
  62.5% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
      19px 0 0 5px, 38px 0 0 0px, 57px 0 0 0px, 19px 19px 0 0px, 38px 19px 0 5px,
      57px 19px 0 5px;
  }
  75% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
      19px 0 0 0px, 38px 0 0 0px, 57px 0 0 5px, 19px 19px 0 0px, 38px 19px 0 0px,
      57px 19px 0 5px;
  }
  87.5% {
    box-shadow: 19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
      19px 0 0 0px, 38px 0 0 5px, 57px 0 0 0px, 19px 19px 0 5px, 38px 19px 0 0px,
      57px 19px 0 0px;
  }
}

.customInput {
  padding: 0.5rem;
  border-width: 1px;
  border-radius: 0.375rem;
  width: 100%;
}

.labelDiv {
  display: flex;
  align-items: center;
  padding: 0 1rem 0 1rem;
  font-weight: 600;
  cursor: pointer;
  width: 220px;
  justify-content: space-between;
  box-sizing: content-box;
}

.labelDiv label {
  text-wrap: nowrap;
}

.textLabelDiv {
  justify-content: center;
}
.textLabelDiv label {
  margin-right: 7px;
  text-wrap: nowrap;
}
.inputDiv {
  width: 100%;
}
.inputDiv textarea {
  height: 200px;
}
.customWrapper {
  display: flex;
  flex-wrap: wrap;
}

.customFlex,
.halfFlex {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0.5rem;
}

.halfFlex {
  width: 50%;
}

.customFlex select {
  min-width: 200px;
}
.customSpan {
  width: 70px;
  display: inline-block;
}
.customSpan label {
  cursor: pointer;
  padding: 0 0.5rem;
  font-size: 16px;
  font-weight: 600;
  user-select: none;
}
.customSpan input {
  width: 17px;
  height: 17px;
  position: relative;
  top: 3px;
}

.custombtn {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-width: 1px;
  border-radius: 0.375rem;
  background-color: #3b82f6;
  color: white;
  margin: 7px;
  font-weight: 600;
}

.Login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(#aaa 0%, #555 100%);
  font-size: 1.5rem;
  padding: 2rem;

  form {
    width: 30rem;
  }
  button {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    margin-top: 10px;
  }
  input {
    display: block;
    outline: none;
    border-radius: 6px;
    padding: 5px 10px;
    margin: 5px 0;
  }
  .form-control {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
}
